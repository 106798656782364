export default {
  props: {
    modelValue: {},
    placeholder: {
      default: "",
    },
    class: {
      type: String,
      default: "",
    },
    itemComponent: {
      type: String,
      default: "iconItem",
    },
    /**
     * url for async list
     */
    asyncListSource: {
      type: [Boolean, String],
      default: false,
    },
    asyncListPagination: {
      default: { start: 0, limit: 9999 },
    },
    /**
     * Adapter to use to get async list
     */
    asyncListRequestAdapter: {
      type: String,
      default: "default",
    },
    /**
     * Hard coded data to send to async list
     */
    asyncListData: {
      type: Object,
      default: {},
    },
    asyncListLive: {
      type: Boolean,
      default: false,
    },
    asyncDataTextFilterParamName: {
      type: String,
      default: "textFilter",
    },
    textFilterAsSaffronListFilter: {
      type: Boolean,
      default: false,
    },
    asyncListParser: {
      type: [Function, Boolean],
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    translateList: {
      type: Boolean,
      default: true,
    },
    showFilterField: {
      type: [Boolean],
      default: true,
    },
    showChevron: {
      type: [Boolean],
      default: true,
    },
    showCloseIcon: {
      type: [Boolean],
      default: true,
    },
    list: {}, // prevent inheritence of list="" on element
    size: {
      type: [String, Boolean],
      default: "m",
    },
    maxListDisplayLength: {
      type: Number,
      default: 30,
    },
  },
  data: function () {
    return {
      passwordVisible: false,
      textFilter: "",
      selectedItem: {
        value: null,
        label: "",
      },
      asyncList: [],
      listLoading: false,
      asyncListTimeout: null,
      listFetchError: false,
      currentHighlight: null,
      selectedValues: this.multiple ? [] : null,
      isDropDownOpened: false,
      filterDebouncedHandler: () => {},
      debounceTextFilter: false,
    };
  },
  emits: [
    "update:modelValue",
    "change",
    "select2:asyncListLoaded",
    "select2:asyncListLoadedSuccess",
    "select2:asyncListLoadedError",
  ],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    localList() {
      let list;
      // TODO: ajax list
      if (this.asyncListSource) {
        return this.asyncList;
      }
      return this.list;
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
    fullDisplayList() {
      let result = [];

      for (const [index, item] of Object.entries(this.localList)) {
        if (this.textFilter !== "") {
          let itemText = this.extractText(item.label);
          let itemFinalText = this.translateIfRequired(itemText).toLowerCase();
          if (itemFinalText.toLowerCase().indexOf(this.textFilter.toLowerCase()) !== -1) {
            result.push(Object.assign({ localIndex: index }, item));
          }
          continue;
        }

        result.push(Object.assign({ localIndex: index }, item));
      }

      return result;
    },
    displayList() {
      let result = this.fullDisplayList;

      if (!this.showFilterField) {
        return result;
      }
      if (result.length > this.maxListDisplayLength) {
        result = result.slice(0, this.maxListDisplayLength);
      }
      return result;
    },
    isDisplayListTruncated() {
      return this.displayList.length < this.fullDisplayList.length;
    },
    wrapperClass() {
      let result = ["saffron-select2-wrapper"];
      result.push(this.class.replace("uk-input", ""));
      return result;
    },
    inputClass() {
      let result = [];
      if (typeof this.class === "string") {
        result = [...result, ...this.class.split(" ")];
      }

      if (this.disabled || this.$attrs.disabled) {
        result.push("disabled");
      }

      result.push("select-2-input");

      if (!this.value || (Array.isArray(this.value) && this.value.length === 0)) {
        result.push("field__element--no-value");
      }
      return result;
    },
    mainInputDisplayValue() {
      if (this.multiple) {
        let result = [];

        if (!this.selectedValues || !this.selectedValues.forEach) {
          return "";
        }
        this.selectedValues.forEach((selectedValue) => {
          let item = this.getItemByValue(selectedValue);
          if (item) {
            result.push(this.translate(this.extractText(item.label)));
          }
        });

        if (result.length < 1) {
          return this.placeholder || "";
        }

        return result.join(", ");
      }

      // try to get item by model value

      let item = this.getItemByValue(this.modelValue);
      if (item) {
        return this.translate(this.extractText(item.label));
      }

      // item not found, but is "prescribed"
      if (this.modelValue && this.modelValue.label) {
        return this.translate(this.extractText(this.modelValue.label));
      }

      // use local selection. TODO: this is probably redundant
      /*
      if (
        this.selectedItem &&
        this.selectedItem.label &&
        this.selectedItem.label !== ""
      ) {
        let text = this.extractText(this.selectedItem.label);
        return this.translate(text);
      }
      */

      // we have nothing...;
      //  console.log('compute 4', this.placeholder)

      //  if (this.placeholder) {
      //   return this.placeholder.replace('* *', '*');
      //  }
      return this.placeholder || "";
    },
    dropdownChevronStyle() {
      if (!this.isDropDownOpened) {
        return {};
      }

      if (this.isLanguageRtl()) {
        return { transform: "rotate(90deg)" };
      } else {
        return { transform: "rotate(-90deg)" };
      }
    },
    anySelected() {
      if (this.multiple) {
        return Array.isArray(this.value) && this.value.length > 0;
      } else {
        return this.value !== null && this.value !== "";
      }
    },
  },
  methods: {
    getItemByValue(val) {
      let result = false;

      if (!Array.isArray(this.localList)) {
        return false;
      }
      this.localList.forEach((item) => {
        if (item.hasOwnProperty("value") && item.value === val) {
          result = item;
          return false;
        }
      });
      return result;
    },
    closeDropdownImmediate() {
      this.isDropDownOpened = false;
    },
    async closeDropdown(immediate = false) {
      if (!immediate) {
        await utilities.wait(200);
      }
      this.closeDropdownImmediate();
      return true;
    },
    openDropdown() {
      this.isDropDownOpened = true;
    },
    toggleItemSelection(item) {
      if (!this.isSelected(item)) {
        this.selectItem(item);
      } else {
        this.deselectItem(item);
      }
    },
    isSelected(item) {
      if (this.multiple) {
        if (!this.selectedValues || !this.selectedValues.filter) {
          return false;
        }
        let foundItems = this.selectedValues.filter((candidate) => {
          return candidate === item.value;
        });

        return foundItems.length > 0;
      } else {
        return this.selectedItem && item.value === this.modelValue;
      }
    },
    selectItem(item) {
      this.value = item.value;
      if (this.multiple) {
        if (!Array.isArray(this.selectedValues)) {
          this.selectedValues = [];
        }
        this.selectedValues.push(item.value);
        this.value = this.selectedValues;
      } else {
        this.closeDropdown();
        this.selectedItem = item;
      }
    },
    deselectItem(item) {
      this.value = item.value;
      if (this.multiple) {
        this.selectedValues = this.selectedValues.filter((candidate) => {
          return candidate !== item.value;
        });
        this.value = this.selectedValues;
      } else {
        this.selectedItem = null;
        this.value = null;
      }
    },
    extractText(htmlString) {
      if (!utilities.isSSR()) {
        let span = document.createElement("span");
        span.innerHTML = htmlString;
        return span.textContent || span.innerText;
      } else {
        return "";
      }
    },
    translateIfRequired(label) {
      return this.autoTranslate ? this.safeTranslate(label) : label;
    },
    getDisplayListItemIndex(target) {
      if (typeof target !== "object" || !target) {
        return null;
      }

      let result = null;

      this.displayList.forEach((item, index) => {
        if (target.label === item.label && target.value === item.value) {
          result = index;
          return false;
        }
      });

      return result;
    },
    async loadAsyncList() {
      this.currentHighlight = null;
      this.listLoading = true;
      this.listFetchError = false;
      this.asyncList = [];

      let data = {};

      data[this.asyncDataTextFilterParamName] = this.textFilter;
      if (this.textFilterAsSaffronListFilter) {
        data.filters = {
          [this.asyncDataTextFilterParamName]: this.textFilter,
        };
      }
      data = Object.assign(data, { ...this.asyncListData });

      if (!data.pagination) {
        data.pagination = this.asyncListPagination;
      }
      let result = await this.asyncOps.asyncCall(this.asyncListSource, data, {
        requestAdapter: this.asyncListRequestAdapter,
      });

      if (result.isError) {
        this.listLoading = false;
        this.listFetchError = true;
        this.asyncList = [];
        this.$emit("select2:asyncListLoadedError", {
          result: result,
          parsed: [],
        });
        this.$emit("select2:asyncListLoaded", { result: result, parsed: [] });
        return;
      }

      this.listLoading = false;
      if (typeof this.asyncListParser === "function") {
        this.asyncList = this.asyncListParser(result.data);
      } else {
        this.asyncList = result.data;
      }

      this.$emit("select2:asyncListLoadedSuccess", {
        result: result,
        parsed: [],
      });
      this.$emit("select2:asyncListLoaded", {
        result: result,
        parsed: this.asyncList,
      });

      return this.asyncList;
    },
    highlightNext() {
      let nextIndex = null;

      if (this.currentHighlight === null || this.currentHighlight < 0) {
        nextIndex = 0;
      } else {
        nextIndex = this.currentHighlight + 1;
      }

      if (nextIndex + 1 > this.displayList.length) {
        return; // we can not hightlight the next item, there isnt one
      }

      this.currentHighlight = nextIndex;
    },
    highlightPrev() {
      let nextIndex = null;

      if (this.currentHighlight === null || nextIndex + 1 >= this.displayList.length) {
        this.currentHighlight = this.displayList.length;
        return;
      }

      nextIndex = this.currentHighlight - 1;
      if (nextIndex < 0) {
        nextIndex = 0;
      }

      this.currentHighlight = nextIndex;
    },
    clearHighlight() {
      this.currentHighlight = null;
    },
    toggleCurrentHighlightSelection() {
      if (this.currentHighlight !== 0 && !this.currentHighlight) {
        return;
      }

      return this.toggleItemSelection(this.displayList[this.currentHighlight]);
      /*
       if (this.multiple && this.selectedValues.includes(this.displayList[this.currentHighlight].value)) {
       this.deselectItem(this.currentHighlight);
       return;
       }

       this.selectItem(this.displayList[this.currentHighlight]);
       */
    },
    listKeyboardNavigation(e) {
      let code = e.keyCode;

      switch (e.keyCode) {
        case 40:
          this.highlightNext();
          e.preventDefault();
          break;

        case 38:
          this.highlightPrev();
          e.preventDefault();
          break;

        case 13:
          this.toggleCurrentHighlightSelection();
          if (!this.multiple) {
            this.closeDropdown();
          }

          e.preventDefault();
          e.stopPropagation();
          break;
        default:
          break;
      }
      // 40 -> down
      // 38 -> up
      // 13 -> enter
    },
    noScrollFocus(el) {
      if (utilities.isSSR()) {
        return;
      }
      let x = window.scrollX,
        y = window.scrollY;
      el.focus();
      window.scrollTo(x, y);
      utilities.setClientTimeout(() => {
        window.scrollTo(x, y);
      }, 50);
    },
    focusDropdown() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },
  },
  watch: {
    asyncListSource: {
      handler: function () {
        if (typeof this.asyncListSource === "string" && this.asyncListSource !== "") {
          this.loadAsyncList();
        }
      },
      immediate: true,
    },
    asyncListData: {
      handler: function () {
        if (typeof this.asyncListSource === "string" && this.asyncListSource !== "") {
          this.loadAsyncList();

        }
      },
      immediate: false,
    },
    asyncListRequestAdapter: {
      handler: function () {
        if (typeof this.asyncListSource === "string" && this.asyncListSource !== "") {
          this.loadAsyncList();
        }
      },
      immediate: false,
    },
    textFilter: {
      handler: function () {
        this.filterDebouncedHandler();
      },
      immediate: false,
    },
    modelValue: {
      handler: function (newVal) {
        this.selectedValues = newVal;
      },
      immediate: true,
    },
    currentHighlight: function () {
      if (this.currentHighlight !== 0 && !this.currentHighlight) {
        return;
      }

      // somehting is highlighted focus it
      try {
        this.$refs["option-" + this.currentHighlight].focus();
      } catch (e) {
        try {
          //this.noScrollFocus();
          this.$refs["option-" + this.currentHighlight].$el.focus({
            preventScroll: true,
          });
        } catch (e) {}
      }
    },
    isDropDownOpened: function (newVal) {
      if (newVal) {
        if (!utilities.isSSR()) {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
          this.currentHighlight = this.getDisplayListItemIndex(this.selectedItem);
        }
      } else {
        // we would like a "Fresh" view of the list.
        // but if the asynclist is live, changing text fillter will cause refresh. so in that case - dont touch that
        if (! this.asyncListLive) {
           this.textFilter = "";
           this.currentHighlight = null;
        }


      }
    },
  },
  created() {
    let newHandler = () => {
      if (this.asyncListLive) {
        clearTimeout(this.asyncListTimeout);
        this.asyncListTimeout = utilities.setClientTimeout(() => {
          this.loadAsyncList();
        }, 200);
      }
    };
    this.filterDebouncedHandler = utilities.debounce(newHandler, 75);
  },
  mounted() {
    // force formatting of default value display
    this.$nextTick(() => {
      let oldValue = this.value;
      this.value = null;
      this.$nextTick(() => {
        this.value = oldValue;
        this.currentHighlight = null;
      });
    });
  },
};
